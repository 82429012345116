import { useState } from 'react';

import { useCurrentOpenings } from "../hooks/current-openings"

export const useFilters = () => {

  const currentOpenings = useCurrentOpenings();

  let filterOptions = new Array;
  filterOptions['positionType'] = new Array;
  filterOptions['positionMarket'] = new Array;
  filterOptions['state'] = new Array;

  let includedValues = new Array;
  includedValues['positionType'] = new Array;
  includedValues['positionMarket'] = new Array;
  includedValues['state'] = new Array;

  currentOpenings.map(node => {
    
    
    if( !includedValues['positionType'].includes(node.acfJobListing.positionType.id) ) {
      filterOptions['positionType'].push([ node.acfJobListing.positionType.id, node.acfJobListing.positionType.name ]);
      includedValues['positionType'].push(node.acfJobListing.positionType.id);
    }
    if( !includedValues['positionMarket'].includes(node.acfJobListing.positionMarket.id) ) {
      filterOptions['positionMarket'].push([ node.acfJobListing.positionMarket.id, node.acfJobListing.positionMarket.name ]);
      includedValues['positionMarket'].push(node.acfJobListing.positionMarket.id);
    }
    if( !includedValues['state'].includes(node.acfJobListing.state) ) {
      filterOptions['state'].push([ node.acfJobListing.state, node.acfJobListing.state ]); 
      includedValues['state'].push(node.acfJobListing.state);
    }
  });
  return filterOptions;
}
