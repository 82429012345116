import * as React from "react"
import {useState} from "react"

// components
import Header from "../components/header"
import Banner from "../components/banner"
import MediaBox from "../components/media-box"
import FeaturedPositions from "../components/featured-positions-box"
import PositionTypesCarousel from "../components/position-types-carousel"
import NewsCarousel from "../components/news-carousel"
import MediaEmbed from "../components/media-embed"
import Footer from "../components/footer"
import SEO from "../components/seo"

import { Container, Row, Col, Button, Modal } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import { Parallax, Background } from 'react-parallax';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import * as styles from "../styles/index.module.scss"

// images

import play from '../images/play.png'
import culture from '../images/culture.png'
import compensation from '../images/compensation.png'
import women from '../images/women.png'
import plate2019 from '../images/plate2019.png'
import plate2020 from '../images/plate2020.png'
import titanAward from '../images/titanAward.png'
import bptw2023 from '../images/bptw2023.png'
import businessjournal2023 from '../images/businessjournal2023.png'
import comparably from '../images/comparably.png'

import grayLinesBG from '../images/background_stack.jpg'

// data
import { usePage } from "../hooks/home-page"
import { useFeaturedPositions } from "../hooks/featured-positions"
import { usePositionTypes } from "../hooks/position-types"
import { useNewsContent } from "../hooks/news"

import { useFilters } from "../hooks/filters"


const IndexPage = () => {
  const home = usePage();
  const dataFeaturedPositions = useFeaturedPositions();
  const dataPositionTypes = usePositionTypes();
  const dataNewsContent = useNewsContent();

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [awardsShow, setAwardsShow] = useState(false);
  const handleAwardsClose = () => setAwardsShow(false);
  const handleAwardsShow = () => setAwardsShow(true);

  const filterOptions = useFilters();

  const HomeFilterSelector = (props) => {

    let options = new Array;
    let handleChange = (e) => {
      //filterJobOpenings(e.currentTarget.id, e.currentTarget.value)
      //updateSelectedFilter([e.currentTarget.id, e.currentTarget.value])
    }


    let selectFilterOptions = props.filterOptions.sort((a, b) => (a[1] > b[1]) ? 1 : -1);
    //console.log('sort');
    //console.log(selectFilterOptions);

    if(props.filterField == 'positionType') {
      selectFilterOptions.unshift(['','Select Position']);
    } else if(props.filterField == 'positionMarket') {
      selectFilterOptions.unshift(['','Select City']);
    } else if(props.filterField == 'state') {
      selectFilterOptions.unshift(['','State']);
    }
    //console.log('selectedFilter')
    //console.log(selectedFilter)
    return (
      <select id={ props.filterField} name={ props.filterField} className="form-control bg-dark border-dark text-white text-uppercase mb-2 mb-lg-0" onChange={handleChange}>
        {selectFilterOptions.map(node => {
             return(
               <option value={ node[0] } >{ node[1] }</option>
             )
        })}
      </select>
    )
  }

  return(
    <div>
      {home.map((node, nIndex) => {
        //Position Card data
        const p1ImageData = node.acfHomePage.positionType1.postitionType1Image.localFile.childImageSharp.fluid
        const p1BackgroundFluidImageStack = [
          p1ImageData,
          `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
        ].reverse()

        const p2ImageData = node.acfHomePage.positionType2.postitionType2Image.localFile.childImageSharp.fluid
        const p2BackgroundFluidImageStack = [
          p2ImageData,
          `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
        ].reverse()

        const p3ImageData = node.acfHomePage.positionType3.postitionType3Image.localFile.childImageSharp.fluid
        const p3BackgroundFluidImageStack = [
          p3ImageData,
          `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
        ].reverse()

        const p4ImageData = node.acfHomePage.positionType4.postitionType4Image.localFile.childImageSharp.fluid
        const p4BackgroundFluidImageStack = [
          p4ImageData,
          `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
        ].reverse()

        const newsBGImageData = node.acfHomePage.newsBackgroundImage.localFile.childImageSharp.fluid
        const newsBackgroundFluidImageStack = [
          newsBGImageData,
          `linear-gradient(90deg,rgba(0,156,101,0.7),rgba(0,156,101,0.2))`,
        ].reverse()

        const coreValuesBGImageData = node.acfHomePage.coreValuesBackgroundImage.localFile.childImageSharp.fluid
        const coreValuesBackgroundFluidImageStack = [
          coreValuesBGImageData,
          `linear-gradient(90deg,rgba(0,156,101,0.9),rgba(26,48,47,0.9))`,
        ].reverse()

        const videoBackgroundFluidImageStack = [
          node.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid,
          `linear-gradient(0deg,rgba(0,0,0,.3),rgba(0,0,0,.8))`,
        ].reverse()

        return (
          <main key={nIndex}>
            <SEO
              title={node.acfSEO.title ? node.acfSEO.title : node.title}
              description={node.acfSEO.metaDescription}
              image={ (node.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid.src != undefined ) ? node.acfPageHeader.videoBoxImage.localFile.childImageSharp.fluid.src : null }
              />
            <Header />
            <Banner
              title={node.acfPageHeader.headerTitle}
              subtitle={node.acfPageHeader.subTitle}
              bodytext={node.acfPageHeader.headerBodyText}
              subtitleFirst="false"
              backgroundImage={node.acfPageHeader.bannerBackgroundImage}
              backgroundVideoUrl={node.acfPageHeader.backgroundVideoUrl}
            >

              <div className="d-flex align-items-center justify-content-center w-100">
                  <button className="d-block h-100 w-100" style={{background: "none", border: "0"}} onClick={handleShow}><img src={play} alt="Play" /></button>
              </div>
              
              <h2 style={{fontSize: 18, fontWeight:500, textTransform:"uppercase", letterSpacing:3}}>{node.acfHomePage.heroCta}</h2>
            </Banner>
            <Modal show={show} onHide={handleClose} centered={true} size="xl">
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body>
                  <MediaEmbed videoUrl={node.acfHomePage.heroPopupUrl} autoplay={true} />
                </Modal.Body>
              </Modal>
            <div style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}>
              <div className={styles.containerFeaturedPositions + " px-2"} style={{ position:'relative',
  zIndex: '100', marginTop:'-25vh' }}>
                <FeaturedPositions
                  title={node.acfFeaturedPositions.featuredPositionsTitle}
                  subtitle={node.acfFeaturedPositions.featuredPositionsSubtitle}
                  navCtaButton={node.acfFeaturedPositions.featuredPositionsNavCtaButton}
                  slideSubtitle={node.acfFeaturedPositions.featuredPositionsSlideSubtitle}
                  ctaButton={node.acfFeaturedPositions.featuredPositionsCtaButton}
                  featuredPositions={dataFeaturedPositions}
                  currentOpeningsButton={true}
                 />
              </div>
              <div id="positions" className="text-center pt-5 pb-5">
                <Container className="background-image-only">
                  <div className={styles.positionsCont} id="newsslider">
                    <PositionTypesCarousel
                    title={node.acfHomePage.positionsTitle}
                    subtitle={node.acfHomePage.positionsSubtitle}
                    description={node.acfHomePage.positionsDescription}
                    positionTypes={dataPositionTypes}
                    />
                  </div>
                </Container>
                
              </div>
            </div>

            <div className="parallax-wrapper">
              <style>
                {`.react-parallax-background-children { width: 100%; min-width: 1600px;}`}

                {`.react-parallax-background.custom-bg:before {
                  content: '';
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  background: linear-gradient(90deg,rgba(0,156,101,0.7),rgba(0,156,101,0.2));
                }`}

                {`.box-buttons {
                  margin-top: 50px;
                }
                .box-buttons .btn {
                  width: 300px;
                  max-width: 100%;
                  border-radius: 30px;
                  margin: 10px auto;
                }
                .btn-outline {
                  color: #009c65;
                  background-color: transparent;
                }
                `}
              </style>

              <Parallax
                className={`text-white`}
                strength={600}
              >
                <Background className="custom-bg">
                  <img src={node.acfHomePage.newsBackgroundImage.localFile.childImageSharp.fluid.src} style={{width: "100%", maxHeight: "1050px"}} />
                </Background>

                <Container className="background-image-only">
                  <div className={styles.newsCont} id="newsslider">
                    <NewsCarousel
                      newsContent={dataNewsContent}
                    />
                  </div>
                </Container>
              </Parallax>
            </div>

            <div style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}>
              <div
                id="awards"
                className={styles.awardsCont}
              >
                <Container>
                  <Row>
                    <Col lg={6}>
                      <h4>{node.acfHomePage.awardsSubtitle}</h4>
                      <h2>{node.acfHomePage.awardsTitle}</h2>
                      <hr />
                      <div className="mt-3" dangerouslySetInnerHTML={{__html: node.acfHomePage.awardsBody}} />
                      <Modal show={awardsShow} onHide={handleAwardsClose} centered={true} size="xl" id="awards-modal">
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body>
                          <h4>{node.acfHomePage.awardsSubtitle}</h4>
                          <h2>{node.acfHomePage.awardsTitle}</h2>
                          <div className="word-text">We've been recognized not only for our success, but also our unique company culture.  Here's a complete list of awards and accolates we've received:</div>
                          <div className="row" dangerouslySetInnerHTML={{__html: node.acfHomePage.awardsList}} />
                        </Modal.Body>
                      </Modal>
                      <div className="d-flex flex-column">
                        <Button className="mb-2" onClick={handleAwardsShow}>
                          See All Awards
                        </Button>
                        <Button className="btn-outline mt-2" href="/current-openings">
                          View Current Openings
                        </Button>
                      </div>
                    </Col>
                    <Col>
                      <Container>
                        <Row className="align-items-center mt-3 text-center">
                          <Col>
                            <img src={culture} alt="2019 Best Company Culture" width="100%" className="w-100" />
                          </Col>
                          <Col>
                            <a rel="noopener" href="https://www.entrepreneur.com/article/357740" target="_blank">
                              <img src={compensation} alt="2020 Best Company Compensation" width="100%" className="w-100" />
                            </a>
                          </Col>
                          <Col>
                            <img src={women} alt="2019 Best Company for Women" width="100%" className="w-100" />
                          </Col>
                        </Row>
                        <Row className="align-items-center mt-3 text-center">
                          <Col>
                            <a rel="noopener" href="https://www.comparably.com/companies/the-connor-group?utm_source=widgets&utm_medium=cfc&utm_campaign=star_rating" target="_blank">
                              <img src={comparably} alt="Comparably 4.6 score" width="200" style={{width: "200px"}} />
                            </a>
                          </Col>
                          <Col>
                            <img src={titanAward} alt="Titan Award" width="100%" className="w-100 titanAwardResize" style={{"max-width": "200px"}} />
                          </Col>
                        </Row>
                        <Row className="align-items-center mt-3 text-center">
                          <Col>
                            <img src={plate2019} alt="2019 Best Workplaces in Ohio" width="100%" className="w-100" />
                          </Col>
                          <Col>
                            <img src={businessjournal2023} alt="2023 Top 100 Companies" width="100%" className="w-100" />
                          </Col>
                          <Col>
                            <img src={plate2020} alt="2020 Best Workplaces in Ohio" width="100%" className="w-100" />
                          </Col>
                        </Row>
                        <Row className="mt-3 text-center">
                          <Col>
                            <a rel="noopener" href="https://www.glassdoor.com/Overview/Working-at-The-Connor-Group-EI_IE301241.11,27.htm" target="_blank">
                              <img src="https://www.glassdoor.com/api/widget/horizontalStarRating.htm?e=301241" alt="Glassdoor" className="w-75" />
                            </a>
                          </Col>
                        </Row>

                        <Row className="align-items-center mt-3 text-center">
                          <Col>
                            <img src={bptw2023} alt="2023 Best Places to Work" width="100%" className="w-100" style={{"max-width": "250px"}} />
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>
 

              </div>


              <div
                id="benefits"
                className={styles.awardsCont}
              >
                <Container>
                  <Row>
                    <Col>
                      <img src={node.acfHomePage.benefitsImage.localFile.childImageSharp.fluid.src} alt="Benefits" width="100%" className="w-100" />
                    </Col>

                    <Col lg={6}>
                      <h4>{node.acfHomePage.benefitsSubtitle}</h4>
                      <h2>{node.acfHomePage.benefitsTitle}</h2>
                      <hr />
                      <div className="mt-3" dangerouslySetInnerHTML={{__html: node.acfHomePage.benefitsBody}} />
                      <div className="d-flex flex-column">
                        <Button href="/benefits">
                          More about benefits
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>

            <BackgroundImage
              className={styles.coreValues}
              Tag="div"
              fluid={coreValuesBackgroundFluidImageStack}
              backgroundColor={`#040e18`}
              id="core-values"
             >
              <h2>{node.acfHomePage.coreValuesTitle}</h2>
              <h4>{node.acfHomePage.coreValuesSubtitle}</h4>
              <Container>
                <Row>
                  <Col lg={6}>
                    <h3>{node.acfHomePage.coreValue1Title}</h3>
                    <div className={styles.valueText}>
                      {node.acfHomePage.coreValue1Body}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <h3>{node.acfHomePage.coreValue2Title}</h3>
                    <div className={styles.valueText}>
                      {node.acfHomePage.coreValue2Body}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <h3>{node.acfHomePage.coreValue3Title}</h3>
                    <div className={styles.valueText}>
                      {node.acfHomePage.coreValue3Body}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <h3>{node.acfHomePage.coreValue4Title}</h3>
                    <div className={styles.valueText}>
                      {node.acfHomePage.coreValue4Body}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h3>{node.acfHomePage.coreValue5Title}</h3>
                    <div className={styles.valueText}>
                      {node.acfHomePage.coreValue5Body}
                    </div>
                  </Col>
                </Row>
              </Container>
            </BackgroundImage>

            <div className={styles.culture} style={{display: 'none'}}>
              <Container>
                <Row>
                  <Col lg={8} className="align-self-center text-center text-lg-left mb-3 mb-lg-0">
                    {node.acfHomePage.cultureText}
                  </Col>
                  <Col className="text-center text-lg-left">
                    <Button rel="noopener" href="https://culture.connorgroup.com" target="_blank">Visit our culture website</Button>
                  </Col>
                </Row>
              </Container>
            </div>

            <div
                id="communitykids"
                className={styles.awardsCont}
                style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}
              >
                <Container>
                  <Row>
                    <Col lg={6}>
                      <Container>
                        <Row>
                          <Col>
                            <h4>{node.acfHomePage.kidsSubhead}</h4>
                            <h2>{node.acfHomePage.kidsHeaderText}</h2>
                            <hr />
                            <div className="mt-3" dangerouslySetInnerHTML={{__html: node.acfHomePage.kidsBody}} />

                            <div className="mt-3 mb-3" style={{width:'50%', marginLeft: 'auto', marginRight: 'auto'}}><img src={node.acfHomePage.kidsCommunityLogo.localFile.childImageSharp.fluid.src} alt="Kids & Community" width="100%" /></div>

                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <div className="d-flex flex-column">
                              {node.acfHomePage.kidsCta && 
                                <Button rel="noopener" style={{width: 'auto'}} className="mt-2 text-uppercase" href={node.acfHomePage.kidsCta} target={node.acfHomePage.kidsCtaNewWindow ? "_blank" : "_self"} >
                                See our efforts in Action
                              </Button>
                              }
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                    <Col>
                      <Container>
                        <Row className="align-items-center mt-3 text-center">
                          <Col>
                          {node.acfHomePage.kidsModalUrl != undefined && node.acfHomePage.kidsVideoImage != undefined ? 
                            <MediaBox
                            orientation="video"
                            videoImage={node.acfHomePage.kidsVideoImage}
                            videoUrl={node.acfHomePage.kidsModalUrl}
                            autoplay={true}
                          ></MediaBox>
                          : node.acfHomePage.kidsVideoImage ?
                            <img src={node.acfHomePage.kidsVideoImage.localFile.childImageSharp.fluid.src} alt="Kids & Community" width="100%" />
                          : null
                          }
                            
                          </Col>
                        </Row>
                      </Container>
                    </Col>
                  </Row>
                </Container>


              </div>

            <div id="reward" className={styles.rewardCont} style={{backgroundImage: "url(" + grayLinesBG + ")", backgroundSize: '100%'}}>
              <MediaBox
                orientation="left"
                title={node.acfHomePage.rewardTitle}
                subtitle={node.acfHomePage.rewardSubtitle}
                videoImage ={node.acfHomePage.rewardVideoImage}
                videoUrl={node.acfHomePage.rewardVideoUrl}
                mobileTitle={true}
              >
                <div className="bg-none">
                  <p className="mt-3">{node.acfHomePage.rewardBody}</p>
                  <div className="text-center">
                    <Button href="/reward-and-recognition" className="text-uppercase">Learn More</Button>
                  </div>
                </div>
              </MediaBox>
            </div>
            <Footer />
          </main>
        )
      })}
    </div>);

}

export default IndexPage
