import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import { Container, Row, Col, Modal, Button } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import MediaEmbed from "../components/media-embed"
import * as styles from './media-box.module.scss'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import play from '../images/play.png'

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style,
        zIndex: '100',
        color: 'rgba(255,255,255,.7)',
        width: '30px',
        right: '-40px'
      }}
      onClick={onClick}
    >
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '25px',
      }} viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style,
        zIndex: '100',
        color: 'rgba(255,255,255,.7)',
        width: '30px',
      }}
      onClick={onClick}
    >
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '25px',
      }} viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
    </div>
      
);
}

const NewsCarousel = (props) => {
  const [hasNewsContent, setHasNewsContent] = useState();
  const [newsShow, setNewsShow] = useState(false);
  const handleNewsClose = () => setNewsShow(false);
  const handleNewsShow = () => setNewsShow(true);
  const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
  
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  useEffect(() => {
    if((props.newsContent != undefined) && (props.newsContent.length != 0)) {
      console.log(props.newsContent.length)
      setHasNewsContent(true)
    }
  }, []);
    
  return hasNewsContent ? 
    <>
      <style>
        {`#newsslider .slick-arrow:before {
          display: none;
        }
        `}
        {`#newsslider .slick-slide img {
                    display: initial;
                }`}
                {`#newsslider .slick-list,
                #newsslider .slick-track {
                  display: flex;
                }
                #newsslider .slick-slide > div,
                #newsslider .slick-slide > div > div,
                #newsslider .slick-slide .bg-light.container,
                #newsslider .slick-slide .bg-light.container > div {
                  height: 100%;
                }
                #newsslider .slick-dots li button:before,
                #newsslider .slick-dots li.slick-active button:before {
                  color: white;
                }
                `}
      </style>
      <Slider {...sliderSettings}>
      {props.newsContent.map((node, idx) => (

          <Container className="bg-light" key={idx}>
            <Row className="px-0">
              {node.featuredImage &&
                <Col lg={6} className="bg-dark p-0 d-flex" style={{minHeight: "300px"}}>
                  <BackgroundImage
                    className="d-flex w-100"
                    Tag="div"
                    fluid={[
                      node.featuredImage.node.localFile.childImageSharp.fluid,
                      `linear-gradient(0deg,rgba(0,0,0,0),rgba(0,0,0,.3))`,
                    ].reverse()}
                    backgroundColor={`#040e18`}
                  >
                    {node.acfNews.newsShowPlayButton &&
                      <div className="d-flex align-items-center justify-content-center w-100">
                        <button className="d-block h-100 w-100" style={{background: "none", border: "0", minHeight: '200px'}} onClick={handleShow}><img src={play} alt="Play" /></button>
                      </div>
                    }
                    {node.acfNews.newsVideoUrl && 
                      <Modal show={show} onHide={handleClose}
                        centered={true} size="xl" >
                        <Modal.Header closeButton></Modal.Header>
                        <Modal.Body >
                          <MediaEmbed videoUrl={node.acfNews.newsVideoUrl} autoplay={true} />
                        </Modal.Body>
                      </Modal>
                    }
                  </BackgroundImage>
                </Col>
              }
              
              <Col>
                <div className={styles.leftTextContent}>
                  { node.acfNews.newsTitle &&
                    <>
                      { node.acfNews.newsSubtitle && 
                      <h4 className="text-primary">{node.acfNews.newsSubtitle}</h4>
                      }
                      <h2 className="mb-0">{node.acfNews.newsTitle}</h2>
                      <hr />
                    </>
                  }
                  {node.content && <div className="mt-3" dangerouslySetInnerHTML={{__html: node.content}} /> }
                  {node.acfNews.newsVideoUrl && 
                    <Modal show={newsShow} onHide={handleNewsClose} centered={true} size="xl">
                      <Modal.Header closeButton></Modal.Header>
                      <Modal.Body>
                        <MediaEmbed videoUrl={node.acfNews.newsVideoUrl} autoplay={true} />
                      </Modal.Body>
                    </Modal>
                  }
                  <div className="box-buttons d-flex flex-column">
                    {node.acfNews.newsVideoUrl && 
                      <Button href="#" onClick={handleNewsShow}>
                        Play Video
                      </Button>
                    }
                    {node.acfNews.newsCtaUrl &&
                      <Button className="btn-outline" href={node.acfNews.newsCtaUrl}  target={node.acfNews.newsCtaNewWindow ? "_blank" : "_self"}>
                        {node.acfNews.newsCtaText}
                      </Button>
                    }
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
      ))}

      </Slider>

    </>

    : null
}
export default NewsCarousel;
