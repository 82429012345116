import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'

export const usePositionTypes = () => {
  const { allWpPositionType } = useStaticQuery(
    graphql`
      query {
        allWpPositionType(
          filter: {acfPositionTypes: {positionTypeImage: { id: {ne: null}}}}
        ) {
          nodes {
            acfPositionTypes {
              positionTypeImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            id
            link
            slug
            name
            description
          }
        }
      }
      
    `
  )
  return allWpPositionType.nodes;
}
