import React, { useState, useEffect } from 'react'
import { Link } from "gatsby"
import { Container, Row, Col, Carousel, Card } from 'react-bootstrap'
import BackgroundImage from 'gatsby-background-image'
import * as styles from "../styles/index.module.scss"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NextArrow = (props) => {
  const { className, style, onClick } = props;

  return (
    <div
      className={className}
      style={{ ...style,
        zIndex: '100',
        color: 'rgba(0,0,0,.7)',
        width: '30px',
      }}
      onClick={onClick}
    >
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-right" className="svg-inline--fa fa-chevron-right fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '25px',
      }} viewBox="0 0 320 512"><path fill="currentColor" d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"></path></svg>
    </div>
  );
}

const PrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style,
        zIndex: '100',
        color: 'rgba(0,0,0,.7)',
        width: '30px',
      }}
      onClick={onClick}
    >
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="chevron-left" className="svg-inline--fa fa-chevron-left fa-w-10 " role="img" xmlns="http://www.w3.org/2000/svg" style={{ fontSize: '25px',
      }} viewBox="0 0 320 512"><path fill="currentColor" d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z"></path></svg>
    </div>
      
);
}

const PositionTypesCarousel = (props) => {
  const [hasPositionTypes, setHasPositionTypes] = useState();
  
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 6000,
    pauseOnHover: true,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          swipeToSlide: true,
          dots: true,
        }
      }
    ]
  };

  useEffect(() => {
    if((props.positionTypes != undefined) && (props.positionTypes.length != 0)) {
      setHasPositionTypes(true)
    }
  }, []);
    
  return hasPositionTypes ? 
    <>
      <h4 className={styles.positionsSubtitle}>{props.subtitle}</h4>
      <h2 className={styles.positionsTitle}>{props.title}</h2>
      <Container className="mb-3">
        <Row className="mx-2 mx-lg-0">
          <Col lg={12} className="p-0">
            <p>{props.description}</p>
          </Col>
        </Row>
      </Container>
      <style>
        {`#positions .slick-arrow:before {
          display: none;
        }
        #positions .slick-dots li button:before,
        #positions .slick-dots li.slick-active button:before {
                  color: rgba(0,0,0,.7);
                }
        `}
      </style>
      <Container lg={12}>
        <Row>
          <Col lg={12} className="p-0">
            <Slider {...sliderSettings}>
              {props.positionTypes.map((node, idx) => (
                <div key={idx} className={idx === 0 ? "active" : ""}>
                  <Container>
                    <Row>
                      <Col lg={12} className="pl-2 pr-2">
                        <BackgroundImage
                          className={styles.positionCard}
                          Tag="div"
                          fluid={[
                            node.acfPositionTypes.positionTypeImage.localFile.childImageSharp.fluid,
                            `linear-gradient(180deg,rgba(0,0,0,0),rgba(0,0,0,.9))`,
                          ].reverse()}
                          backgroundColor={`#040e18`}
                          style={{backgroundPosition: "0% 25%"}}
                        >
                          <Link to={'/'+node.slug}>
                            <div className={styles.positionCardContent}>
                              <h3>{node.name}</h3>
                              <hr />
                              <div className={styles.positionCardText}>
                                {node.description}
                              </div>
                              <div className={styles.learnMore}>
                                Learn More
                              </div>
                            </div>
                          </Link>
                        </BackgroundImage>
                      </Col>
                    </Row>
                  </Container>
                </div>
              ))}
            </Slider>
          </Col>
        </Row>
      </Container>
    </>

    : null
}
export default PositionTypesCarousel;
