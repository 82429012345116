import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'

export const useNewsContent = () => {
  const { allWpNews } = useStaticQuery(
    graphql`
      query {
        allWpNews(limit: 5) {
          nodes {
            acfNews {
              newsTitle
              newsSubtitle
              newsCtaText
              newsCtaUrl
              newsCtaNewWindow
              newsShowPlayButton
              newsVideoUrl
            }
            content
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 700) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            title
            slug
          }
        }
      }
    `
  )
  return allWpNews.nodes;
}
