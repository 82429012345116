import React, { useState } from 'react';
import { useStaticQuery, graphql } from 'gatsby'

export const usePage = () => {
  const { allWpPage } = useStaticQuery(
    graphql`
      query {
        allWpPage(filter: {uri: {eq: "/"}}) {
          nodes {
            id
            title
            acfPageHeader {
              headerBodyText
              headerTitle
              headerSubtitle
              videoBoxBodyText
              videoBoxSubtitle
              videoBoxTitle
              videoBoxVideoUrl
              videoBoxImage{
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              backgroundVideoUrl
              bannerBackgroundImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            acfFeaturedPositions {
              featuredPositionsSubtitle
              featuredPositionsTitle
              featuredPositionsNavCtaButton
              featuredPositionsSlideSubtitle
              featuredPositionsCtaButton
            }
            acfSEO {
              metaDescription
              title
            }
            acfHomePage {
              awardsBody
              awardsList
              awardsTitle
              awardsBackgroundImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              coreValue4Body
              awardsSubtitle
              benefitsTitle
              benefitsSubtitle
              benefitsBody
              benefitsImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              coreValue1Body
              coreValue1Title
              coreValue2Body
              coreValue3Body
              coreValue2Title
              coreValue3Title
              coreValue4Title
              coreValue5Title
              coreValue5Body
              coreValuesSubtitle
              coreValuesTitle
              coreValuesBackgroundImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              cultureText
              newsSubtitle
              newsTitle
              newsBody
              newsVideoUrl
              newsBackgroundImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              newsVideoImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              newsBody2
              newsVideoUrl2
              newsVideoImage2 {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              positionsTitle
              positionsSubtitle
              positionsDescription
              positionType1 {
                fieldGroupName
                postitionType1Body
                positionType1PositionType {
                  ... on WpPositionType {
                    id
                    name
                  }
                }
                postitionType1Title
                postitionType1Image {
                  localFile {
                    childImageSharp{
                      fluid(quality: 90, maxWidth: 500) {
                                  ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              positionType2 {
                fieldGroupName
                postitionType2Body
                positionType2PositionType {
                  ... on WpPositionType {
                    id
                    name
                  }
                }
                postitionType2Title
                postitionType2Image {
                  localFile {
                    childImageSharp{
                      fluid(quality: 90, maxWidth: 500) {
                                  ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              positionType3 {
                fieldGroupName
                postitionType3Body
                positionType3PositionType {
                  ... on WpPositionType {
                    id
                    name
                  }
                }
                postitionType3Title
                postitionType3Image {
                  localFile {
                    childImageSharp{
                      fluid(quality: 90, maxWidth: 500) {
                                  ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              positionType4 {
                fieldGroupName
                postitionType4Body
                positionType4PositionType {
                  ... on WpPositionType {
                    id
                    name
                  }
                }
                postitionType4Title
                postitionType4Image {
                  localFile {
                    childImageSharp{
                      fluid(quality: 90, maxWidth: 500) {
                                  ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              kidsHeaderText
              kidsSubhead
              kidsBody
              kidsCta
              kidsCtaNewWindow
              kidsModalUrl
              kidsVideoImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1000) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              kidsCommunityLogo {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 500) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              heroTitle
              heroCta
              heroPopupUrl
              rewardBody
              rewardSubtitle
              rewardTitle
              rewardBackgroundImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              rewardVideoUrl
              rewardVideoImage {
                localFile {
                  childImageSharp{
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
       }
    `
  )
  return allWpPage.nodes;
}
